<template>
  <div v-if="product" class="container-fluid padding-md margin-top-60 product-detail">
    <div class="row">
      <div class="col-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/#/">Accueil</a></li>
            <li class="breadcrumb-item"><a :href="back.url">{{back.title}}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{product.name}}</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-8">
        <div class="main-img" :style="{backgroundImage: 'url(' + currentImage + ')' }">
          <ribbon :padding="{left: '15px'}" v-if="product.active_tags.new_article"/>
        </div>

        <div class="row margin-top-10">
          <div class="col-12">
            <img
                v-for="(img, index) in product.images"
                :key="`img-${index}`" :src="img"
                class="product-th-image thumbnail float-left"
                @click="selectImage(img)" />
          </div>
        </div>

        <div class="col-12 product-info-container" v-if="has_product_info">
          <div class="row product-info-item" v-if="product.producer">
            <div class="col-12 product-info">
              <div class="row">
                <div class="col-3 product-info-name">
                  <span>Le Producteur</span>
                  <img :src="'https://www.clubtiossane.sn/ckossam/' + product.producer.imageUrl" v-if="product.producer.imageUrl" alt="Producer image">
                </div>
                <div class="col-9 product-info-content">
                  <div class="title big-title">{{ product.producer.name }}</div>
                  <div class="sub-title" v-if="product.producer.location">{{product.producer.location}}</div>
                  <p>{{product.producer.description}}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row product-info-item" v-if="product.conservation">
            <div class="col-12 product-info">
              <div class="row">
                <div class="col-3 product-info-name">
                  <span>Conservation</span>
                </div>
                <div class="col-9 product-info-content">
                  <img :src="product.conservation.image" v-if="product.conservation.image" alt="Conservation image">
                  <div class="title">{{ product.conservation.title }}</div>
                  <p>{{product.conservation.description}}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row product-info-item" v-if="product.ingredients">
            <div class="col-12 product-info">
              <div class="row">
                <div class="col-3 product-info-name">
                  <span>Ingredients</span>
                </div>
                <div class="col-9 product-info-content">
                  <img :src="product.ingredients.image" v-if="product.ingredients.image" alt="Ingredients image">
                  <div class="title">{{ product.ingredients.title }}</div>
                  <p>{{product.ingredients.description}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h2><span class="badge badge-warning">{{product.category.name}}</span></h2>
              </div>
              <div class="col-2">
                <a v-if="!temporary" @click="changeLike()">
                  <span class="ic-md" v-show="liked" style="font-size: 1.5em; color: #D6403F;">
                    <i class="fas fa-heart"></i>
                  </span>
                  <span class="ic-md" v-show="!liked" style="font-size: 1.5em; color: #D6403F;">
                    <i class="far fa-heart"></i>
                  </span>
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h1>{{product.name}}</h1>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <p>{{product.description}}</p>
              </div>
            </div>
            <div class="row order">
              <div class="col-6" v-if="product.orderable">
                <button @click="add()" class="btn btn-primary" v-if="is_add_state" >Ajouter</button>
                <div class="label" v-if="is_qte_state">Quantité</div>
                <div class="col-12 px-0 product-qte" v-if="is_qte_state">
                  <button @click="minusOne()" class="btn btn-qte input-qte-minus">-</button>
                  <input :disabled="true" v-model="quantity" class="form-control input-qte" type="text" />
                  <button @click="plusOne()" class="btn btn-qte">+</button>
                </div>
              </div>
              <div class="col-6">
                <div class="label">Prix</div>
                <div class="value">{{product.price | formatIntAmount}} FCFA</div>
              </div>
            </div>
            <product-frequency
              v-if="!product.isOneShotable && is_qte_state"
              :productId="product.id"
              :oneShot="oneShot"
              :inCart="true"
              :isBox="false"
            ></product-frequency>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import EventBus from './packs/eventBus'
import service from './packs/services'

export default {
  props: ["productId", "rubrique", "category"],
  data: function () {
    return {
      status: 'add',
      currentImage: '',
      quantity: 0,
      cart: null,
      product: null,
      liked: false,
      temporary: true
    }
  },
  async mounted() {
    this.product = await service.getProduct(this.productId)
    this.cart = await service.getTCartContent()
    let likes = await service.getLikes()
    if(likes) {
      this.liked = true
    }

    //this.currentImage = this.product.images[0]
    this.currentImage = `https://www.clubtiossane.sn/ckossam/${this.product.imgUrl}`
    this.temporary = this.cart.temporary
    if (this.cart.order_items) {
      let ordered = this.cart.order_items.find(p => p.product?.id === this.product.id)
      if(ordered) {
        this.quantity = ordered.quantity
      }
    }
    this.status = (this.quantity > 0) ? 'set_quantity' : 'add'
  },
  computed: {
    is_add_state(){
      return this.status === 'add'
    },

    is_qte_state(){
      return this.status === 'set_quantity'
    },

    has_product_info() {
      return this.product.producer || this.product.conservation || this.product.ingredients
    },

    back() {
      if (this.rubrique) {
        return {title: this.rubrique.title, url: `/rubrique/${this.rubrique.id}#/`};
      } else if (this.category) {
        return {title: this.category.title, url: `/category/${this.category.id}#/`};
      }
      return {url: '#', title: this.product.name};
    },

    oneShot() {
      if (!this.cart.order_items) return false

      const orderItem = this.cart.order_items.find(p => p.product?.id === this.product.id)
      if (!orderItem) return

      return orderItem.oneShot
    }
  },
  methods: {

    async add(){
      this.status = 'set_quantity'
      await this.plusOne()
    },

    selectImage(img) {
      this.currentImage = img
    },

    async changeLike() {
      this.liked = !this.liked;
      await service.setLike(this.product.id, this.liked)
    },

    async plusOne() {
      this.quantity++;
      await service.setCartQuantity(this.product.id, this.quantity)
      EventBus.$emit("OrderNeedValidation", {})
    },

    async minusOne() {
      if(this.quantity > 0) {
        this.quantity--;
        await service.setCartQuantity(this.product.id, this.quantity)
        EventBus.$emit("OrderNeedValidation", {})
        if(this.quantity === 0){
          this.status = 'add'
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>

.product-detail .card {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 25px 25px;
}

.product-detail .badge {
  font-family: Montserrat,serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 15px;
  text-transform: uppercase;
  color: #fff;
  background: #E97834;
  border-radius: 5px;
}

.product-detail h1 {
  font-family: Recoleta,serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
}

.product-detail .card-body .row {
  margin-bottom: 20px;
}

.product-detail .card-body p {
  font-family: Montserrat,serif;
  font-style: normal;
  font-size: 14px;
}

.product-detail {
  .main-img {
    width: 100%;
    height: 60vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
  .label {
    font-family: Recoleta,serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    padding: 10px 5px;
  }
  .value {
    font-family: Recoleta,serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
}

.product-detail .thumbnail {
  width: 110px;
  height: 110px;
  margin-right: 10px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.product-detail .product-info {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px;
  border-style: solid;
  border-color: #BDBDBD;
  .product-info-content {
    img {
      float: left;
      max-width: 30%;
      margin: 0;
      padding: 0.5em;
    }
    p {
      font-family: Montserrat, serif;
      font-style: normal;
      font-size: 16px;
      line-height: 25px;
      font-weight: 400;
      margin-top: 0.5em;
    }
  }
  .product-info-name {
    padding-top: 0.4em;
    span {
      display: block;
    }
    img {
      width: 100%;
      margin-top: 0.5em;
    }
  }
  .title {
    font-family: Montserrat, serif;
    font-style: normal;
    font-size: 1.1em;
    margin-top: 0.2em;
  }
  .sub-title {
    font-family: Montserrat, serif;
  }
  .big-title {
    font-weight: bold;
    font-size: 1.2em;
  }
  .product-info-content {
    padding-left: 0;
  }
}

.product-info-container {
  margin-bottom: 3rem;
  margin-top: 3rem;
  border-top: 1px;
  border-style: solid;
  border-color: #BDBDBD;
}

.product-th-image {
  cursor: pointer;
}

.product-qte {
  margin-top: 5px;
  display: inline-flex;
}

.btn-qte {
  width: 30px;
  height: 30px;
  padding: 5px 2px;
  line-height: 10px;
  font-family: Recoleta,serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  color: #2A6336;
  border: 1px solid #2A6336;
  border-radius: 4px;
}

.input-qte:disabled, .input-qte[readonly] {
  background-color: #fff;
}

.input-qte {
  width: 30px;
  height: 30px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 4px;
  font-family: Montserrat,serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #757575;
  padding: 8px 0;
  text-align: center;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
}

.input-qte-minus {
  padding-top: 6px;
}

.card-text-price {
  margin-bottom: 0;
}

.select {
  padding: 5px;
  background-color: #fff;
  color: #2A6336;
  border-radius: 8px;
  height: 35px;
  font-family: Montserrat,serif;
  font-size: 12px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
}

.select.active {
  color: #fff;
  background-color: #2A6336;
}

</style>
