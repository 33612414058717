<template>
  <div v-if="cart" class="container">

    <div v-if="todayOrder" class="row justify-content-center">
      <div @click="gotoHistoryOrder()" class="today-order">
        <div class="title">
          Commande en cours de livraison
        </div>
        <div class="action">
          Voir détail <i class="fa fa-angle-down"></i>
        </div>
      </div>
    </div>

    <div class="row p-4 form-section">
      <div v-if="unValidated" class="col-12 text-ftm-1 text-center color-red">
        Produits non-confirmés dans votre panier
      </div>
    </div>

    <!-- MY CART -->
    <div v-if="cart && cart.order_items.length > 0" class="row p-2 justify-content-center">
      <div class="accordion" id="accordionUnvalidated">
        <div class="card">
          <div class="card-header section-cart-products" id="headingUnvalidated">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseUnvalidated" aria-expanded="false" aria-controls="collapseUnvalidated">
                Voir les produits non-confirmés
              </button>
            </h2>
          </div>

          <div id="collapseUnvalidated" class="collapse show" aria-labelledby="headingUnvalidated" data-parent="#accordionUnvalidated">
            <div class="card-body">
              <div class="d-flex flex-col flex-wrap justify-content-around">
                <div class="product-container" v-for="item in cartBoxes" v-bind:key="item.id">
                  <ro-cart-box-item :key="item.id" :box="item.box" :quantity="item.quantity" :oneShot="item.oneShot" />
                </div>
                <div class="product-container"
                  v-for="item in cartProducts"
                  v-bind:key="item.id"
                >
                  <ro-cart-item :item="item" :likes="likes" />
                </div>
              </div>
              <div class="row p-2 justify-content-center">
                <button @click="addToOrder()" class="btn btn-lg btn-primary mr-2">Ajouter à la commande</button>
                <a href="/rubrique/12" class="btn btn-lg btn-secondary">Continuer vos courses</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row p-4 form-section">
      <div v-if="empty" class="col-12 text-ftm-1 text-center">
        Aucune livraison future programmée
      </div>
      <div v-if="orderValidated" class="col-12 text-ftm-1 text-center">
        Livraison confirmée pour le {{order.date | formatFriendlyDateFull}} - {{orderTotal | formatAmount}} FCFA <i class="fas fa-check"></i>
      </div>
    </div>

    <div class="row p-2 justify-content-center">
      <a v-if="empty" href="/rubrique/12" class="btn btn-lg btn-primary">Faites vos courses</a>
    </div>

    <!-- MY ORDER -->
    <div v-if="orderValidated" class="row p-2 justify-content-center">
      <div class="accordion" id="accordionValidated">
        <div class="card">
          <div class="card-header section-cart-products" id="headingValidated">
            <h2 class="mb-0">
              <button class="btn btn-link btn-block text-left color-primary" type="button" data-toggle="collapse" data-target="#collapseValidated" aria-expanded="false" aria-controls="collapseValidated">
                Voir / Modifier mon panier
              </button>
            </h2>
          </div>

          <div id="collapseValidated" class="collapse show" aria-labelledby="headingValidated" data-parent="#accordionValidated">
            <div class="card-body" v-if="likes">
              <ro-order-item-list :boxes="orderBoxes" :items="orderProducts"></ro-order-item-list>
              <div class="row p-2 justify-content-center">
                <button @click="editOrder()" class="btn btn-lg btn-primary mr-2">Modifier commande</button>
                <a href="/rubrique/12"  class="btn btn-lg btn-secondary">Continuer vos courses</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row p-2 justify-content-center">
      <div class="col-6 form-section">
        <div v-if="orderValidated">
          <div class="form-row">
            <div class="form-group col-12 required">
              <textarea placeholder="Une indication pour cette livraison, un horaire à respecter, un contact à appeler, etc."
                v-model="orderComment" name="orderComment" class="form-input size-1" />
            </div>
          </div>
          <div class="form-row justify-content-center">
            <div class="form-group d-flex">
              <button @click="addOrderComment()" type="button" class="btn btn-primary btn-lg" >Envoyer</button>
            </div>
          </div>
        </div>
        <div>
          <div class="row mb-2 ml-2">
            <span>Solde disponible : {{user.balance | formatAmount}} FCFA</span>
          </div>
          <div class="btn-group-vertical">
            <a href="/account/payment/wave" class="btn btn-outline-success text-left"><span class="fa fa-angle-right float-right f-btn"></span>Recharger solde par WAVE</a>
            <a href="/account/payment/om" class="btn btn-outline-success text-left"><i class="fa fa-angle-right float-right f-btn"></i>Recharger solde par OM</a>
            <a href="/account/payment/cb" class="btn btn-outline-success text-left"><i class="fa fa-angle-right float-right f-btn"></i>Recharger solde par Carte bancaire</a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import eventBus from './packs/eventBus'
import service from './packs/services'
import wave from './packs/wave'

export default {
  props: ["userId"],

  data: function () {
    return {
      user: null,
      order: null,
      cart: null,
      boxes: [],
      likes: null,
      needValidateDate: false,
      selectedDate: null,
      paymentMode: 'next-delivery',
      paymentProvider: {
        value: 'WAVE',
        label: 'WAVE'
      },
      paymentAmount: null,
      amountToPay: 0,
      amountToCharge: 0,
      startPayment: false,
      delivery_fee: 0,
      orderComment: null,
      todayOrder: null,
      glassBalance: null
    }
  },
  async mounted() {
    this.refresh()
    eventBus.$on('OrderNeedValidation', function() {
      this.refresh()
    }.bind(this))

    eventBus.$on('OrderUpdated', function() {
      this.refresh()
    }.bind(this))

    eventBus.$on('ProductDeleted', function() {
      this.refresh()
    }.bind(this))

    eventBus.$on('DateChanged', function(event) {
      this.needValidateDate = true
      this.selectedDate = event.selectedDate
      if(this.selectedDate.free) {
        this.delivery_fee = 0
      }else {
        this.delivery_fee = parseInt(this.selectedDate.deliveryFee)
      }
    }.bind(this))
  },

  computed: {
    cartProducts() {
      return this.cart.order_items.filter(item => !item.panier)
    },

    cartBoxes() {
      return this.cart.order_items.filter(item => item.panier)
    },

    orderProducts() {
      return this.order.order_items.filter(item => item.type === 'product')
    },

    /** init boxes */
    orderBoxes() {

      let orderedBoxes = []
      this.boxes.forEach(box => {
        let occurrence = this.order.order_items.find(oi => oi.type === 'box' && oi.box_id == box.id)
        if(occurrence) {
          let productBox = box.box_items.find(b => occurrence.product.id == b.productId)
          box.quantity = occurrence.quantity / productBox.quantity
          box.oneShot = occurrence.oneShot
          orderedBoxes.push(box)
        }
      });
      return orderedBoxes
    },

    needValidation() {
      if (this.unValidated) {
        return true
      }

      return this.needValidateDate
    },

    paymentTypes() {
      return [
        {
          value: 'WAVE',
          label: "WAVE"
        }, {
          value: 'OM',
          label: "Orange Money"
        }, {
          value: 'CB',
          label: "Carte bancaire"
        }]
    },

    unValidated() {
      return this.unValidatedProducts.length > 0
    },

    unValidatedProducts() {
      return this.cart.order_items
    },

    amountDue() {
      if (this.user.balance >= this.orderTTC) return 0
      return this.orderTTC - this.user.balance
    },

    orderTTC() {
      return this.orderTotal + this.orderFees
    },

    orderTotal() {
      let total = 0
      this.order.order_items.forEach(item => {
        total += item.quantity * item.price
      });
      return total
    },

    orderFees() {
      return this.delivery_fee
    },

    cartTotal() {
      if(this.validated) {
        return 0
      }
      let total = 0
      this.cart.order_items.forEach(item => {
        let price = 0
        if (item.panier) {
          price = item.box.price
        }else {
          price = item.product.price
        }
        total += item.quantity * price
      });
      return total
    },

    /**
     * if no order and cartempty
     */
    empty() {
      if (this.order.order_items.length == 0 && this.cart.order_items.length == 0) {
        return true
      }
      return false
    },

    /**
     * if no order and cartempty
     */
    orderValidated() {
      if (this.order && this.order.order_items.length > 0) {
        return true
      }
      return false
    },

    /**
     * Compute the amount total to pay
     */
    total() {
      this.amountToPay = 0
      let amount = parseInt(this.paymentAmount);
      if(this.paymentProvider.value == 'OM' && isFinite(amount) && amount > 0) {
        let percentOM = 0.99
        this.amountToPay = Math.ceil(amount / percentOM)
        return this.amountToPay;
      }

      if (this.paymentProvider.value == 'CB' && isFinite(amount) && amount > 0) {
        let percent = 0.98;
        this.amountToPay = Math.ceil(amount / percent)
        return this.amountToPay ;
      }

      if (this.paymentProvider.value == 'WAVE' && isFinite(amount) && amount > 0) {
        let percent = 0.99;
        this.amountToPay = Math.ceil(amount / percent)
        return this.amountToPay ;
      }
      return this.amountToPay;
    },

  },
  methods: {

    payment() {
      this.paymentMode = 'next-delivery'
      this.onPaymentTypeChanged('nextDelivery')
      this.startPayment = true
    },

    async refresh() {
      this.user = await service.getUser(this.userId)
      this.likes = await service.getLikes()
      this.boxes = await service.getBoxes()
      this.order = await service.getCartContent()
      this.todayOrder = await service.getTodayOrder()
      this.cart = await service.getTCartContent()
      this.glassBalance = await service.getGlassBalance()
      this.delivery_fee = this.order.delivery_fee ? parseInt(this.order.delivery_fee) : 0
      this.orderComment = this.order.comment
    },

    /**
     * Method validate
     */
    async validate(){
      try {
        if(this.needValidateDate) {
          await service.updateDeliveryDate(this.user.id, this.selectedDate.date, this.delivery_fee)
          this.needValidateDate = false
          eventBus.$emit("DeliveryDateUpdated", {})
        }
        if(this.unValidated) {
          let result = await service.validateCart()
          this.order = result
        }
        eventBus.$emit("OrderValidated", {})
        await this.refresh()
      } catch (err) {
        if(err.response.status == 422) {
          if (err.response.data.code == 'ERR21H') {
            this.$swal({
              icon: 'warning',
              cancelButtonText: 'OK',
              cancelButtonColor: '#2A6336',
              title: "Oups!",
              text: "Vous ne pouvez pas être livré(e) ce jour car votre panier contient un produit non livrable sous 24h. Veuillez choisir une autre date ou retirer ce(s) article(s) de votre panier.",
              showCancelButton: true,
              showConfirmButton: false
            });
          }
          if (err.response.data.code == 'ERR_BLOCKED_WEEK') {
            this.$swal({
              icon: 'warning',
              cancelButtonText: 'OK',
              cancelButtonColor: '#2A6336',
              title: "Désolé!",
              html: "<p style=\"text-align: left;\">En raison de la Tabaski nous ne livrons pas ce jour, merci de choisir un autre jour de livraison SVP.</p>",
              showCancelButton: true,
              showConfirmButton: false
            });
          }
        }
      }
    },

    /**
     * Method pay
     */
    async pay(){
      const currentLink = window.location.href;
      if (this.amountToPay < 500) {
        this.$swal({
          icon: 'warning',
          cancelButtonText: 'OK',
          cancelButtonColor: '#2A6336',
          title: "Oups!",
          text: "Le montant à payer en ligne doit etre au minimum de 500 Fcfa",
          showCancelButton: true,
          showConfirmButton: false
        });
        return
      }
      switch(this.paymentProvider.value) {
        case 'OM':
          this.$refs.formPayment.submit()
          break
        case 'CB':
          const orderId = (new Date().getTime()) + '-' + this.userId
          sendPaymentInfos(orderId,
            'TIOSN8424',
            '3jQbQoQr?cbfcwH6VMPtfOGlNtlV-Q$Eh#5Ud5ylJt94ESE^zN',
            'www.clubtiossane.sn', currentLink,
            currentLink, this.amountToPay,
            'dakar', '', '', '', '')
          break
        case 'WAVE':
          const payment_ref = (new Date().getTime()) + '-' + this.userId
          const resp = await wave.makePayment(this.amountToPay, payment_ref)
          window.location.href = resp.payment_url
          break
      }


      //EventBus.$emit("OrderValidated", {})
      //this.startPayment = false
      //this.paymentExpanded = false

    },

    async cancelPay(){
      this.startPayment = false
    },

    /**
     * On payment changed
     */
    onPaymentTypeChanged(paymentType) {
      let paymentTypeAmount = {
        debt: () => this.user.balance,
        debtNextDelivery: () => this.amountDue,
        nextDelivery: () => this.amountDue
      };

      this.paymentAmount = paymentTypeAmount[paymentType] ? paymentTypeAmount[paymentType]() : 0
    },

    /**
     * Go to edit order
     */
    editOrder() {
      window.location.href = '/my-cart'
    },

    /**
     * Confirm le cart et navigue vers update space
     */
    async addToOrder() {
      window.location.href = '/my-cart'
    },

    async addOrderComment(){
      await service.addOrderComment(this.orderComment)
      this.$swal({
        icon: "success",
        cancelButtonText: "OK",
        cancelButtonColor: "#2A6336",
        title: "Envoyé",
        text: "Message envoyé",
        showCancelButton: true,
        showConfirmButton: false,
      });
    },

    gotoHistoryOrder() {
      eventBus.$emit("HistoryDetail", {})
    },

  }
}
</script>

<style lang='scss' scoped>

.product-container {
  display: flex;
  justify-content: center;
  margin: 0 0 1rem;
  width: 100%;

  @media screen and (min-width: 767px) {
    margin: 0 1rem 1rem 0;
    max-width: 312px;
    width: calc(50% - 1rem);
  }
}

.accordion{
  width: 100%;
  .card {
    height: auto;
    margin-bottom: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 0px;
  }
  .card-header {
    position: relative;
    background: #FFFFFF;
    padding: .5rem .75rem;
    button.btn-block {
      display: flex;
      align-items: center;
      &:after {
        flex-shrink: 0;
        width: 1rem;
        height: 1rem;
        margin-left: auto;
        content: "";
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-size: 1rem;
        transition: transform .2s ease-in-out;
        box-sizing: border-box;
      }
      &[aria-expanded="true"]:after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        transform: rotate(180deg);
      }
    }
  }

  .card-body {
    font-family: Montserrat;
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;

    @media screen and (min-width: 767px) {
      padding-right: 0.25rem;
    }
  }

  .card button.btn-block,
  .card button.btn-block:hover,
  .card button.btn-block:active,
  .card button.btn-block:focus {
    font-family: Recoleta;
    font-size: 1.2rem;
    font-weight: 600;
    color: #D6403F;
    text-decoration: none;
    box-shadow: none;
  }
}

.verre-label {
  margin-left: 10px;
  font-family: 'Montserrat SemiBold';
  font-weight: bold;
}

.verre-value {
  margin-left: 10px;
  font-family: 'Montserrat';
}

.amount {
  margin-left: 10px;
  font-family: 'Recoleta';
  font-weight: bold;
}

.currency {
  font-family: 'Montserrat';
}

.row-payment {
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.row-payment > div {
  padding-left: 2px;
  padding-right: 2px;
  span {
    line-height: 30px;
  }
}

.row-recap {
  background: #F4F3F0;
}

.section-verre{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.section-title {
  text-align: center;
  color: #2A6336;
}

hr {
  background-color: #2A6336;
}

.select {
  width: 200px;
  padding: 5px;
  background-color: #fff;
  color: #2A6336;
  border-radius: 8px;
  height: 35px;
  font-family: Montserrat;
  font-size: 12px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.f-btn {
  height: 25px;
}

.btn-group-vertical {
  width: 350px;
}

.today-order {
  background-color: #e97834;
  border-radius: 12px;
  width: 400px;
  padding: 20px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  .title {
    font-family: 'Montserrat SemiBold';
    font-size: 18px;
  }
  .action {
    margin-top: 5px;
    font-family: 'Montserrat';
    font-size: 15px;
  }
}

</style>
