import Vue from 'vue/dist/vue.esm'
import moment from 'moment'
import numeral from 'numeral'

numeral.register('locale', 'fr', {
  delimiters: {
      thousands: '.',
      decimal: ','
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  },
  ordinal : function (number) {
      return number === 1 ? 'er' : 'ème';
  },
  currency: {
      symbol: 'FCFA'
  }
});

// switch between locales
numeral.locale('fr');

Vue.filter('formatDate', function (value) {
  if(!value) return ""
  return moment(value).format('DD MMMM YYYY')
})

Vue.filter('formatDay', function (value) {
  if(!value) return ""
  return moment(value).format('dddd')
})

Vue.filter('formatMonth', function (value) {
  if(!value) return ""

  return moment(value).format('DD MMMM')
})

Vue.filter('formatFriendlyDate', function (value) {
  if(!value) return ""
  return moment(value).format('dddd DD MMM')
})

Vue.filter('formatFriendlyDateFull', function (value) {
  if(!value) return ""
  return moment(value).format('dddd DD MMMM')
})

/**
 * Format amount withDecimal
 */
Vue.filter('formatAmount', function (value) {
  if (!value) value = 0
  const n = 2
  const x = 3
  const s = ' '
  const c = ','
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = value.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
})

/**
 * Format amount without decimal
 */
Vue.filter('formatIntAmount', function (value) {
  if (!value) value = 0
  const n = 0
  const x = 3
  const s = ' '
  const c = ','
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = value.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
})
