<template>
  <div class="product-item mb-3">
    <div class="product-img">
      <img :src="`https://www.clubtiossane.sn/ckossam/${item.box.imgUrl}`" />
    </div>

    <div class="product-body">
      <div class="product-body-desc">
        <div class="title">
          {{item.box.name}}
        </div>
        <div class="price">
          {{item.box.price | formatIntAmount}} FCFA
        </div>
      </div>
      <div class="product-body-actions">
        <div class="config" >
          <div class="product-qte">
            <button @click="minusOne()" class="btn btn-qte input-qte-minus">-</button>
            <input :disabled="true" v-model="item.quantity" class="form-control input-qte" type="text" />
            <button @click="plusOne()" class="btn btn-qte">+</button>
          </div>
        </div>
      </div>

      <div v-if="!item.box.isOneShotable" class="frequency-container">
        <product-frequency
          :oneShot="item.oneShot"
          :productId="item.box.id"
          :inCart="true"
          :isBox="true"
        ></product-frequency>
      </div>
    </div>
  </div>
</template>

<script>
import service from './packs/services'
import EventBus from './packs/eventBus'

export default {
  props: ['item'],
  data: function () {
    return {
      frequency: {}
    }
  },

  computed: {

  },

  methods: {

    async plusOne() {
      this.item.quantity++;
      await service.setBoxQuantity(this.item.box.id, this.item.quantity)
      EventBus.$emit("OrderNeedValidation", {})
    },

    async minusOne() {
      if(this.item.quantity > 0) {
        this.item.quantity--;
        await service.setBoxQuantity(this.item.box.id, this.item.quantity)
        EventBus.$emit("OrderNeedValidation", {})
      }
    },

  }
}
</script>

<style lang='scss' scoped>

.frequency-container {
  padding: 0.5rem 1rem;
  margin: 0 auto;
}

.product-item {
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  min-height: 13vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  .product-img {
    width: 30%;
    display: block;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .product-body {
    width: 70%;
    justify-content: space-around;
    height: 100%;
    flex-direction: column;
    display: flex;

    .product-body-desc {
      display: flex;
      justify-content: space-between;
      padding: 10px 10px 0px;

      .title {
        font-family: Recoleta;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
      }
      .price {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
      }
    }
    .product-body-actions {
      padding: 0.5rem;
      .config {
        height: 100%;
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .product-qte {
          display: inline-flex;
        }
      }
    }
  }
}



.card-body-title {
  height: 60px;
}

.card-title {
  margin-bottom: 5px;
}

.card-text-price {
  font-size: 10px;
  margin-bottom: 3px;
}

.cart-item {
  margin-bottom: 10px;
  background-color:#fff;
}

.cart-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.btn-qte {
  width: 30px;
  height: 30px;
  padding: 5px 2px;
  line-height: 10px;
  font-family: Recoleta;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  color: #2A6336;
  border: 1px solid #2A6336;
  border-radius: 4px;
}

.input-qte:disabled, .input-qte[readonly] {
  background-color: #fff;
}

.input-qte {
  width: 30px;
  height: 30px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 4px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #757575;
  padding: 8px 0px;
  text-align: center;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.input-qte-minus {
  padding-top: 6px;
}

.select {
  margin-left: 5px;
  padding: 5px;
  background-color: #fff;
  color: #2A6336;
  border-radius: 4px;
  height: 25px;
  font-family: Montserrat;
  font-size: 10px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.select.active {
  color: #fff;
  background-color: #2A6336;
}

</style>
